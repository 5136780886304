/*
 * Designer Mode
 */

 .dmContainer {
  -webkit-animation: fadeIn ease-in 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeIn ease-in 1s; /* Firefox < 16 */
  -ms-animation: fadeIn ease-in 1s; /* Internet Explorer */
  -o-animation: fadeIn ease-in 1s; /* Opera < 12.1 */
  animation: fadeIn ease-in 1s;
}