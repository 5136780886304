/*
 * Designer Mode - Edit Layer
 */

.dm_editLayer_container {
  height: calc(100vh - 66px);
  background-color: rgb(20, 20, 20);
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 66px;
  width: 281px;
  overflow-y: auto;
  overflow-x: hidden;
  border-left: 2px solid rgb(50, 50, 50);
}

.dm_editLayer_title {
  padding: 15px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Lato";
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 2px;
  border-bottom: 1px solid #323232;
  border-top: 1px solid #323232;
}

.addPxSuffixToInput::after {
  content: "px";
  width: 1em;
  height: 1em;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  opacity: 0.5;
  cursor: default;
}

.dm_editorLayer_textboxPropertiesContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 10px;
}

.dm_editLayer_textboxContainer {
  width: 100px;
  height: 50px;
  padding-top: 20px;
}

.dm_editLayer_colorContainer {
  height: 70px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.dm_editLayer_colorBox {
  width: 50px;
  height: 20px;
  border: 5px solid white;
  background-color: white;
  position: relative;
}

.dm_editLayer_colorTitle {
  font-family: "Lato";
  position: relative;
  padding: 8px 0px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
}


.dm_editLayer_currentImage {
  width: 200px;
  height: fit-content;
  max-height: 300px;
  margin: 10px auto;
}

.dm_editLayer_dropdownContainer {
  width: 200px;
  padding-top: 29px;
  display: flex;
}


/* Upload Font ===== */

.droppableBox{
  position: absolute;
  width: 96%;
  height: 220px;
  margin-top: 48px;
  background-color: rgba(103, 103, 103, 0.78);
  z-index: 1;
  border: 1px solid rgb(255, 255, 255);
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 7px;
  font-size: 20px;
  color: #FFF;
  text-transform: uppercase;
}
.dm_editLayerUploadFont{
  width: 40px;
  height: 40px;
  background-image: url('../../img/uploadFont.png');
  background-size: contain;
  margin-left: 6px;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}

.dm_editLayerUploadFont:hover .tooltiptext {
  bottom: 50px;
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

.dm_editLayerUploadFont .tooltiptext {
  background-color: rgb(54, 54, 54);
  color: rgb(143, 143, 143);
  text-align: center;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  width: 85px;
  bottom: 120%;
  left: 50%;
  margin-left: -48px;
  font-size: 10px;
  visibility: hidden;
  opacity: 0;
  text-transform: uppercase;
  transition: all 0.2s;
}

.dm_editLayerUploadFont .tooltiptext:before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 40px;
}



/* select starting stylings ------------------------------*/
.select {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  position: relative;
  width: 100%;
  border: 1px solid rgba(131, 131, 131, 0.5);
  border-radius: 0px;
}

.select-text {
  position: relative;
  font-family: inherit;
  background-color: transparent;
  width: 100%;
  padding: 10px 10px 10px 10px;
  font-size: 15px;
  border-radius: 0;
  border: none;
  color: rgba(131, 131, 131, 1);
}

/* Remove focus */
.select-text:focus {
  outline: none;
  border-bottom: 1px solid rgba(131, 131, 131, 0.5);
}

/* Use custom arrow */
.select .select-text {
  appearance: none;
  -webkit-appearance: none;
}

.select:after {
  position: absolute;
  top: 18px;
  right: 10px;
  /* Styling the down arrow */
  width: 0;
  height: 0;
  padding: 0;
  content: "";
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgba(131, 131, 131, 0.5);
  pointer-events: none;
}

/* LABEL ======================================= */
.select-label {
  color: rgba(131, 131, 131, 1);
  font-size: 15px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 10px;
  transition: 0.2s ease all;
  letter-spacing: 2px;
  text-transform: uppercase;
}

/* active state */
.select-text:focus ~ .select-label,
.select-text:valid ~ .select-label {
  color: rgba(131, 131, 131, 0.5);
  background-color: rgba(19, 19, 19, 1);
  padding-left: 10px;
  padding-right: 10px;
  top: -9px;
  transition: 0.2s ease all;
  font-size: 12px;
}

/* BOTTOM BARS ================================= */
.select-bar {
  position: relative;
  display: block;
  width: 100%;
}

.select-bar:before,
.select-bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: rgba(19, 19, 19, 1);
  transition: 0.2s ease all;
}

.select-bar:before {
  left: 50%;
}

.select-bar:after {
  right: 50%;
}

/* active state */
.select-text:focus ~ .select-bar:before,
.select-text:focus ~ .select-bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.select-highlight {
  position: absolute;
  height: 60%;
  width: 100%;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* Text Align ================================== */


/*
 * Img
 */

.dm_editorLayer_textAlignLeft {
  width: 40px;
  height: 40px;
  background-image: url("../../img/left.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px;
  margin: 10px;
  border-radius: 7px;
  background-color: transparent;
}

.dm_editorLayer_textAlignCenter {
  width: 40px;
  height: 40px;
  background-image: url("../../img/center.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px;
  margin: 10px;
  border-radius: 7px;
  background-color: transparent;
}

.dm_editorLayer_textAlignRight {
  width: 40px;
  height: 40px;
  background-image: url("../../img/right.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px;
  margin: 10px;
  border-radius: 7px;
  background-color: transparent;
}

.dm_editorLayer_textAlignTop {
  width: 40px;
  height: 40px;
  background-image: url("../../img/top.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px;
  margin: 10px;
  border-radius: 7px;
  background-color: transparent;
}

.dm_editorLayer_textAlignMiddle {
  width: 40px;
  height: 40px;
  background-image: url("../../img/middle.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px;
  margin: 10px;
  border-radius: 7px;
  background-color: transparent;
}

.dm_editorLayer_textAlignBottom {
  width: 40px;
  height: 40px;
  background-image: url("../../img/bottom.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px;
  margin: 10px;
  border-radius: 7px;
  background-color: transparent;
}

/*
 * Checked
 */

input[type="radio"]:checked + .dm_editorLayer_textAlignLeft {
  background-color: rgb(255, 255, 255, 0.12);
  transition: all 0.25s linear;
}

input[type="radio"]:checked + .dm_editorLayer_textAlignCenter {
  background-color: rgb(255, 255, 255, 0.12);
  transition: all 0.25s linear;
}

input[type="radio"]:checked + .dm_editorLayer_textAlignRight {
  background-color: rgb(255, 255, 255, 0.12);
  transition: all 0.25s linear;
}

input[type="radio"]:checked + .dm_editorLayer_textAlignTop {
  background-color: rgb(255, 255, 255, 0.12);
  border-radius: 7px;
  transition: all 0.25s linear;
}

input[type="radio"]:checked + .dm_editorLayer_textAlignMiddle {
  background-color: rgb(255, 255, 255, 0.12);
  border-radius: 7px;
  transition: all 0.25s linear;
}

input[type="radio"]:checked + .dm_editorLayer_textAlignBottom {
  background-color: rgb(255, 255, 255, 0.12);
  border-radius: 7px;
  transition: all 0.25s linear;
}

/*
 * Hover
 */
 
input[type="radio"] + .dm_editorLayer_textAlignLeft:hover {
  background-color: rgb(255, 255, 255, 0.1);
  transition: all 0.25s linear;
}

input[type="radio"] + .dm_editorLayer_textAlignCenter:hover {
  background-color: rgb(255, 255, 255, 0.1);
  transition: all 0.25s linear;
}

input[type="radio"] + .dm_editorLayer_textAlignRight:hover {
  background-color: rgb(255, 255, 255, 0.1);
  transition: all 0.25s linear;
}

input[type="radio"] + .dm_editorLayer_textAlignTop:hover {
  background-color: rgb(255, 255, 255, 0.1);
  transition: all 0.25s linear;
}

input[type="radio"] + .dm_editorLayer_textAlignMiddle:hover {
  background-color: rgb(255, 255, 255, 0.1);
  transition: all 0.25s linear;
}

input[type="radio"] + .dm_editorLayer_textAlignBottom:hover {
  background-color: rgb(255, 255, 255, 0.1);
  transition: all 0.25s linear;
}

.dm_editorLayerHideInput {
  display: none;
}
