.tig_container{
  margin: auto;
  padding-top: 100px;
  padding-bottom: 30px;
}

.tig_main{
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center center;
}