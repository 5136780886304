body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #131313;
  color: rgb(143, 143, 143);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*
 * TOP
 */
.topContainerIntro {
  height: 45px;
  padding: 10px;
}

.topContainerIntro::after {
  content: "";
  height: 1px;
  display: block;
  width: 0%;
  background-color: #838383;
  margin-left: -10px;
  animation: slideIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 0.4s;
  animation-delay: 0s;
}
.topContainer {
  height: 45px;
  padding: 10px;
  border-bottom: 1px solid #838383;
}

@keyframes slideIn {
  from {
    width: 0vw;
  }
  to {
    width: 100vw;
  }
}

.topMenuBack {
  padding: 10px 20px;
  width: 80px;
  cursor: pointer;
}
.topMenuBack:hover {
  color: #fff;
  -webkit-transition: color 0.2s;
  -moz-transition: color 0.2s;
  transition: color 0.2s;
}

/*
 * Footer
 */

.footerContainer {
  height: 80px;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #131313;
  text-align: center;
  color: #404040;
  float: left;
  text-align: left;
  font-size: 20px;
  line-height: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-direction: column;
  flex-direction: column;
  line-height: normal;
}
.footerText {
  padding: 0px 100px 0px 12px;
  font-size: 13px;
  letter-spacing: 2px;
}
.footerTextBold {
  font-weight: bold;
  margin-left: 8px;
}
.footerDot {
  padding: 0px 7px;
}
.footerTextSmall {
  font-size: 10px;
}

.topImg {
  background-image: url("./img/sm.png");
  background-size: contain;
  width: 100%;
  height: 45px;
  background-repeat: no-repeat;
}

.helpButton {
  font-family: "Lato";
  background-color: #131313;
  color: #424242;
  padding: 0px 12px;
  border-color: #131313;
  font-size: 40px;
  font-weight: 900;
  cursor: pointer;
}

#helpButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.helpButton:focus {
  outline: none;
}

.helpButtonRotate {
  animation: rotate-center 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: border 0.4s ease-in 0.4s;
  border: 1px solid #838383;
}

.helpButtonInfo {
  position: absolute;
  margin: -50px 0px 0px -281px;
  width: 280px;
  height: 48px;
  border: 1px solid #838383;
  opacity: 0%;
  background-color: #131313;
}
.helpButtonInfoAnimation {
  animation: fadeIn ease-in 0.4s;
  animation-fill-mode: forwards;
  animation-duration: 0.4s;
  animation-delay: 0.4s;
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.helpButtonInfoLine1 {
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 12px;
  color: #424242;
  padding: 5px 0px 0px 10px;
}
.helpButtonInfoLine2 {
  letter-spacing: 1px;
  font-weight: 900;
  color: #fc0000;
  text-decoration: none;
  padding: 0px 0px 0px 10px;
}
.helpButtonInfoLine2 a {
  text-decoration: none;
  color: #fc0000;
  font-size: 12px;
}

/*
 * Login
 */

.mainContainer {
  background-color: #131313;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.teamInfoLogo {
  background-image: url("./img/logo.png");
  width: 100px;
  height: 100px;
  background-size: contain;
  margin: 50px auto;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 0.4s;
  animation-delay: 0s;
}

@keyframes fadeIn {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

.teamInfoLogoLong {
  background-image: url("./img/long_logo.png");
  width: 255px;
  height: 52px;
  background-size: contain;
  margin: 20px auto;
}

.middleContainer {
  flex: 1 1 0%;
  width: 300px;
  margin: auto;
}

.loginTextbox {
  background: #131313;
  border: 1px solid #838383;
  padding: 0px 20px;
  width: -webkit-fill-available;
  height: 25px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  color: #fff;
  margin-bottom: 25px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.inputContainer {
  margin: 10px 0px;
}
.loginMessage {
  text-align: center;
  text-transform: uppercase;
  padding: 10px;
  font-size: 12px;
}

/*
 * 2. Layouts
 */

.layouts_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 30px;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.layouts_box {
  width: 220px;
  height: 260px;
  margin: 20px 30px;
}

.layouts_box_bg {
  width: 200px;
  height: 220px;
  background-color: rgb(54, 54, 54);
  padding: 0px 10px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-origin: content-box;
  cursor: pointer;
}

.layouts_box_bg:hover {
  background-color: rgb(143, 143, 143);
  transition-property: background-color;
  transition-duration: 120ms;
  transition-timing-function: ease-in;
}

.layouts_box_text {
  width: 180px;
  padding: 5px 20px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  height: 50px;
  letter-spacing: 1px;
  font-size: 12px;
  cursor: pointer;
}

.layouts_box_add {
  width: 40px;
  height: 40px;
  background-color: rgb(54, 54, 54);
  margin: 110px 110px;
  font-weight: bold;
  text-align: center;
  font-size: 30px;
  cursor: pointer;
  color: rgb(19, 19, 19);
  position: relative;
  display: inline-block;
}

.layouts_box_add:hover {
  background-color: rgb(143, 143, 143);
  transition-property: background-color;
  transition-duration: 120ms;
  transition-timing-function: ease-in;
}
.layouts_box_add:hover .tooltiptext {
  bottom: 50px;
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

.tooltiptext {
  background-color: rgb(54, 54, 54);
  color: rgb(143, 143, 143);
  text-align: center;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  width: 120px;
  bottom: 120%;
  left: 50%;
  margin-left: -60px;
  font-size: 10px;

  visibility: hidden;
  opacity: 0;
  text-transform: uppercase;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

.tooltiptext:before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 40px;
}

/*
 * Add Layout
 */

.addLayout_setion {
  background-color: rgb(29, 29, 29);
  height: -webkit-fill-available;
}

.addLayout_container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 40px 40px;
}
.addLayout_box {
  width: 240px;
  height: 280px;
}

input[type="radio"] {
  visibility: hidden;
}

label {
  cursor: pointer;
}
.check {
  display: block;
  border: 2px solid rgb(143, 143, 143);
  border-radius: 100%;
  height: 25px;
  width: 25px;
  top: 30px;
  left: 20px;
  z-index: 5;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
  margin: auto;
}

input[type="radio"]:checked + label .check {
  background-image: -webkit-radial-gradient(circle, #de0e10 40%, #1d1d1d 44%);
}
.labelProps {
  cursor: pointer;
}

input[type="radio"]:checked + label {
  color: rgb(202, 202, 202);
  transition: all 0.25s linear;
}

label:hover {
  color: rgb(202, 202, 202);
}

label:hover .check {
  border: 2px solid rgb(202, 202, 202);
  transition: all 0.25s linear;
}

.addLayoutButtonContainer {
  padding: 50px 50px;
  width: 180px;
  margin: auto;
}
.addLayout_square {
  width: 40px;
  height: 40px;
  background-color: #373737;
  margin: 40px auto;
  padding: 50px 40px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 18px;
  transition: all 0.25s linear;
  user-select: none;
}

.addLayout_horizontal {
  width: 90px;
  height: 20px;
  background-color: #373737;
  margin: 70px auto;
  padding: 30px 30px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 12px;
  transition: all 0.25s linear;
  user-select: none;
}

.addLayout_vertical {
  width: 40px;
  height: 50px;
  background-color: #373737;
  margin: 40px auto;
  padding: 40px 20px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 18px;
  transition: all 0.25s linear;
  user-select: none;
}
.addLayout_custom {
  width: 110px;
  height: 80px;
  margin: 70px auto;
  padding: 10px 30px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 12px;
  border: 2px solid #373737;
  transition: all 0.25s linear;
}

.addLayout_input {
  border: none;
  background: #1c1c1c;
  width: 80px;
  color: rgb(143, 143, 143);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}

.addLayout_input:focus {
  outline: none;
}
.addLayout_customTitle {
  padding-bottom: 10px;
}

.addLayoutMessage {
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  padding: 10px 0px;
  font-size: 11px;
  font-weight: bold;
}

.addLayout_nameContainer {
  width: 300px;
  margin: auto;
}

@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

/*
 * Scroll bar
 */

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-thumb {
	background-color: rgb(50,50,50);
  border: 4px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
	background-color: rgb(70,70,70);
}

::-webkit-scrollbar-track {
	background-color: rgb(25,25,25);
  border-left: 2px solid rgb(50,50,50);
  border-right: 2px solid rgb(50,50,50);
}




/*
 * Text box
 */
.pure-material-textfield-outlined {
  --pure-material-safari-helper1: rgb(
    var(--pure-material-primary-rgb, 131, 131, 131)
  );
  position: relative;
  display: inline-block;
  padding-top: 6px;
  font-family: var(
    --pure-material-font,
    "Roboto",
    "Segoe UI",
    BlinkMacSystemFont,
    system-ui,
    -apple-system
  );
  font-size: 14px;
  line-height: 1.5;
  overflow: hidden;
  width: 100%;
}

/* Input, Textarea */
.pure-material-textfield-outlined > input,
.pure-material-textfield-outlined > textarea {
  box-sizing: border-box;
  margin: 0;
  border: solid 1px; /* Safari */
  border-color: rgba(var(--pure-material-onsurface-rgb, 131, 131, 131), 0.6);
  border-top-color: transparent;
  border-radius: 0px;
  padding: 7px 12px 7px;
  width: 100%;
  height: inherit;
  color: rgba(var(--pure-material-onsurface-rgb, 131, 131, 131), 0.87);
  background-color: transparent;
  box-shadow: none; /* Firefox */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  caret-color: rgb(var(--pure-material-primary-rgb, 131, 131, 131));
  transition: border 0.2s, box-shadow 0.2s;
}

/* Span */
.pure-material-textfield-outlined > input + span,
.pure-material-textfield-outlined > textarea + span {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  border-color: rgba(var(--pure-material-onsurface-rgb, 131, 131, 131), 0.6);
  width: 100%;
  max-height: 100%;
  color: rgba(var(--pure-material-onsurface-rgb, 131, 131, 131), 0.6);
  font-size: 75%;
  line-height: 15px;
  cursor: text;
  transition: color 0.2s, font-size 0.2s, line-height 0.2s;
  text-transform: uppercase;
  letter-spacing: 2px;
}

/* Corners */
.pure-material-textfield-outlined > input + span::before,
.pure-material-textfield-outlined > input + span::after,
.pure-material-textfield-outlined > textarea + span::before,
.pure-material-textfield-outlined > textarea + span::after {
  content: "";
  display: block;
  box-sizing: border-box;
  margin-top: 6px;
  border-top: solid 1px;
  border-top-color: rgba(
    var(--pure-material-onsurface-rgb, 131, 131, 131),
    0.6
  );
  min-width: 10px;
  height: 8px;
  pointer-events: none;
  box-shadow: inset 0 1px transparent;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.pure-material-textfield-outlined > input + span::before,
.pure-material-textfield-outlined > textarea + span::before {
  margin-right: 4px;
  border-left: solid 1px transparent;
  border-radius: 0px 0;
}

.pure-material-textfield-outlined > input + span::after,
.pure-material-textfield-outlined > textarea + span::after {
  flex-grow: 1;
  margin-left: 4px;
  border-right: solid 1px transparent;
  border-radius: 0 0px;
}

/* Hover */
.pure-material-textfield-outlined:hover > input,
.pure-material-textfield-outlined:hover > textarea {
  border-color: rgba(var(--pure-material-onsurface-rgb, 131, 131, 131), 0.87);
  border-top-color: transparent;
}

.pure-material-textfield-outlined:hover > input + span::before,
.pure-material-textfield-outlined:hover > textarea + span::before,
.pure-material-textfield-outlined:hover > input + span::after,
.pure-material-textfield-outlined:hover > textarea + span::after {
  border-top-color: rgba(
    var(--pure-material-onsurface-rgb, 131, 131, 131),
    0.87
  );
}

.pure-material-textfield-outlined:hover > input:not(:focus):placeholder-shown,
.pure-material-textfield-outlined:hover
  > textarea:not(:focus):placeholder-shown {
  border-color: rgba(var(--pure-material-onsurface-rgb, 131, 131, 131), 0.87);
}

/* Placeholder-shown */
.pure-material-textfield-outlined > input:not(:focus):placeholder-shown,
.pure-material-textfield-outlined > textarea:not(:focus):placeholder-shown {
  border-top-color: rgba(
    var(--pure-material-onsurface-rgb, 131, 131, 131),
    0.6
  );
}

.pure-material-textfield-outlined > input:not(:focus):placeholder-shown + span,
.pure-material-textfield-outlined
  > textarea:not(:focus):placeholder-shown
  + span {
  font-size: inherit;
  line-height: 48px;
}

.pure-material-textfield-outlined
  > input:not(:focus):placeholder-shown
  + span::before,
.pure-material-textfield-outlined
  > textarea:not(:focus):placeholder-shown
  + span::before,
.pure-material-textfield-outlined
  > input:not(:focus):placeholder-shown
  + span::after,
.pure-material-textfield-outlined
  > textarea:not(:focus):placeholder-shown
  + span::after {
  border-top-color: transparent;
}

/* Focus */
.pure-material-textfield-outlined > input:focus,
.pure-material-textfield-outlined > textarea:focus {
  border-color: rgb(var(--pure-material-primary-rgb, 131, 131, 131));
  border-top-color: transparent;
  box-shadow: inset 1px 0 var(--pure-material-safari-helper1),
    inset -1px 0 var(--pure-material-safari-helper1),
    inset 0 -1px var(--pure-material-safari-helper1);
  outline: none;
}

.pure-material-textfield-outlined > input:focus + span,
.pure-material-textfield-outlined > textarea:focus + span {
  color: rgb(var(--pure-material-primary-rgb, 131, 131, 131));
}

.pure-material-textfield-outlined > input:focus + span::before,
.pure-material-textfield-outlined > input:focus + span::after,
.pure-material-textfield-outlined > textarea:focus + span::before,
.pure-material-textfield-outlined > textarea:focus + span::after {
  border-top-color: var(--pure-material-safari-helper1) !important;
  box-shadow: inset 0 1px var(--pure-material-safari-helper1);
}

/* Disabled */
.pure-material-textfield-outlined > input:disabled,
.pure-material-textfield-outlined > input:disabled + span,
.pure-material-textfield-outlined > textarea:disabled,
.pure-material-textfield-outlined > textarea:disabled + span {
  border-color: rgba(
    var(--pure-material-onsurface-rgb, 0, 0, 0),
    0.38
  ) !important;
  border-top-color: transparent !important;
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
  pointer-events: none;
}

.pure-material-textfield-outlined > input:disabled + span::before,
.pure-material-textfield-outlined > input:disabled + span::after,
.pure-material-textfield-outlined > textarea:disabled + span::before,
.pure-material-textfield-outlined > textarea:disabled + span::after {
  border-top-color: rgba(
    var(--pure-material-onsurface-rgb, 0, 0, 0),
    0.38
  ) !important;
}

.pure-material-textfield-outlined > input:disabled:placeholder-shown,
.pure-material-textfield-outlined > input:disabled:placeholder-shown + span,
.pure-material-textfield-outlined > textarea:disabled:placeholder-shown,
.pure-material-textfield-outlined > textarea:disabled:placeholder-shown + span {
  border-top-color: rgba(
    var(--pure-material-onsurface-rgb, 0, 0, 0),
    0.38
  ) !important;
}

.pure-material-textfield-outlined
  > input:disabled:placeholder-shown
  + span::before,
.pure-material-textfield-outlined
  > input:disabled:placeholder-shown
  + span::after,
.pure-material-textfield-outlined
  > textarea:disabled:placeholder-shown
  + span::before,
.pure-material-textfield-outlined
  > textarea:disabled:placeholder-shown
  + span::after {
  border-top-color: transparent !important;
}

/* Faster transition in Safari for less noticable fractional font-size issue */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .pure-material-textfield-outlined > input,
    .pure-material-textfield-outlined > input + span,
    .pure-material-textfield-outlined > textarea,
    .pure-material-textfield-outlined > textarea + span,
    .pure-material-textfield-outlined > input + span::before,
    .pure-material-textfield-outlined > input + span::after,
    .pure-material-textfield-outlined > textarea + span::before,
    .pure-material-textfield-outlined > textarea + span::after {
      transition-duration: 0.1s;
    }
  }
}

/*
 * Button
 */

.pure-material-button-outlined {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  border: solid 1px;
  border-color: transparent;
  border-radius: 0px;
  padding: 0 16px;
  min-width: 64px;
  height: 36px;
  vertical-align: middle;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  color: rgb(var(--pure-material-primary-rgb, 143, 143, 143));
  background-color: rgb(54, 54, 54);
  font-size: 14px;
  font-weight: 700;
  line-height: 34px;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  width: 100%;
  letter-spacing: 2px;
}

.pure-material-button-outlined::-moz-focus-inner {
  border: none;
}

/* Overlay */
.pure-material-button-outlined::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: currentColor;
  opacity: 0;
  transition: opacity 0.2s;
}

/* Ripple */
.pure-material-button-outlined::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  padding: 50%;
  width: 32px;
  height: 32px;
  background-color: currentColor;
  opacity: 0;
  transform: translate(-50%, -50%) scale(1);
  transition: opacity 1s, transform 0.5s;
}

/* Hover, Focus */
.pure-material-button-outlined:hover::before {
  opacity: 0.04;
}

.pure-material-button-outlined:focus::before {
  opacity: 0.12;
}

.pure-material-button-outlined:hover:focus::before {
  opacity: 0.16;
}

/* Active */
.pure-material-button-outlined:active::after {
  opacity: 0.16;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0s;
}
