
/*
 * Designer Mode - Layers
 */

.dm_layers_container {
  height: calc(100vh - 66px);
  background-color: rgb(20, 20, 20);
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 66px;
  width: 281px;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 2px solid rgb(50,50,50);
}

.dm_layers_title{
  padding: 15px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Lato";
  font-weight: 700;
  font-size: 17px;
  letter-spacing: 2px;
}

.dm_layers_icons{
  display: flex;
}


.dm_layers_list {
  padding: 20px 24px;
  flex-grow: 1;
  min-height: 100px;
}

.dm_layers_item {
  border-radius: 2px;
  margin-bottom: 0px;
  background-color: rgb(20, 20, 20);
  -webkit-user-select: none;
  user-select: none;
  padding: 1px 0px;
}

.dm_layers_groupItem {
  margin-left: 10px;
}

.dm_layers_selectedLayer{
  border-color: rgb(125, 125, 125) !important;
}
.dm_layers_labelGroupItem{
  width: 150px !important;
}


/*
 * Layers - Checkboxes
 */

 .dm_layers_checkboxButton {
  cursor: grab;
}

input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}


.dm_layers_checkboxButtonInput {
  opacity: 0;
  position: absolute;
}

.dm_layers_checkboxButtonControl {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 12px;
  vertical-align: middle;
  background-color: inherit;
  color: rgb(125, 125, 125);
  border: 2px solid rgb(70,70,70);
  cursor: pointer;
}

.dm_layers_checkboxButtonInput:checked + .dm_layers_checkboxButtonControl:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  background-color: rgb(125, 125, 125);
}

.checkboxdm_layers_checkboxButtonInput:checked + .dm_layers_checkboxButtonControl {
  border-color: rgb(125, 125, 125);
}

.dm_layers_checkboxButtonControl {
  transform: scale(0.75);
}
.dm_layers_checkboxButtonLabel {
  border: 1px solid rgb(70,70,70);
  color: rgb(125, 125, 125);
  padding: 2px 10px;
  width: 160px;
  background-color: transparent;
  font-family: 'Lato';
  font-weight: 700;
  font-size: 12px;
  cursor: grab;
}