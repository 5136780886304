/*
 * Add Layer Lightbox Menu
 */


 .dm_addLayer_lightbox {
  display: flex;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.3);
  animation: fadeIn ease-in 0.2s;
}

.dm_addLayer_box {
  margin: 10% auto;
  width: 500px;
  height: fit-content;
  background-color: rgba(0,0,0,0.8);
  /* box-shadow: 2px 2px 16px rgba(51, 51, 51, 1); */
  box-shadow:  0 4px 30px rgba(0,0,0,.2);
}

.dm_addLayer_layerTypesContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;
}


.dm_addLayer_boxTitle{
  padding-bottom: 15px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Lato";
  font-weight: 700;
  font-size: 17px;
  letter-spacing: 2px;
}

.dm_addLayer_closeBox{
  width: 25px;
  height: 25px;
  text-align: center;
  margin: auto 95%;
  font-family: 'Lato';
  font-weight: 600;
  cursor: pointer;
}


.dm_addLayer_textContainer{
  width: 125px;
}

.dm_addLayer_icons{
  width: 22px;
  height: 22px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 10px auto;
}

.dm_addLayer_layerTypeTitle{
  width: auto;
  font-family: 'Lato';
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  border-top: 1px rgba(150, 150, 150, 0.7) solid;
  padding-top: 8px;
  border-bottom: 1px rgba(150, 150, 150, 0.7) solid;
  padding-bottom: 8px;
}

.dm_addLayer_optionContainer{
  cursor: pointer;
  width: 125px;
  animation: fadeIn ease-in 0.2s;
}
.dm_addLayer_optionContainer:hover{
  background-color: #272727;
}

.dm_addLayer_layerInfo{
  padding: 10px;
  text-transform: uppercase;
  font-family: 'Lato';
  font-size: 10px;
}



/*
 *  Add Layer Icons
 */

 .dm_addLayer_textIcon{
  background-image: url('../../img/layer_text.png');
}

.dm_addLayer_imageIcon{
  background-image: url('../../img/layer_image.png');
}

.dm_addLayer_imageGroupIcon{
  background-image: url('../../img/layer_imageGroup.png');

}

.dm_addLayer_photoUploadIcon{
  background-image: url('../../img/layer_photoUpload.png');
}






/*
 * Add Layer Icon
 */
.dm_addLayer_layersAddIcon {
  width: 22px;
  height: 22px;
  background-color: rgb(124, 124, 124);
  margin: 20px 0px 0px 70px;
  font-weight: bold;
  text-align: center;
  font-size: 25px;
  cursor: pointer;
  color: rgb(19, 19, 19);
  position: relative;
  display: inline-block;
  line-height: 20px;
}

.dm_addLayer_layersAddIcon:hover {
  background-color: rgb(143, 143, 143);
  transition-property: background-color;
  transition-duration: 120ms;
  transition-timing-function: ease-in;
}
.dm_addLayer_layersAddIcon:hover .dm_addLayer_layersAddIcon_tooltiptext {
  bottom: 34px;
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
} 

.dm_addLayer_layersAddIcon .dm_addLayer_layersAddIcon_tooltiptext {
  background-color: rgb(54, 54, 54);
  color: rgb(143, 143, 143);
  text-align: center;
  padding: 0px;
  position: absolute;
  z-index: 1;
  width: 85px;
  bottom: 120%;
  left: 110%;
  margin-left: -60px;
  font-size: 10px;
  visibility: hidden;
  opacity: 0;
  text-transform: uppercase;
  transition: all 0.2s;
}
