.dm_header_container{
  height: 45px;
  padding: 10px;
  border-bottom: 1px solid rgba(250, 0, 0, 1);
  background-color: rgba(20, 20, 20, 1);
  position: fixed;
  z-index: 5;
  width: 99%;
  display: flex;
  flex-wrap: nowrap;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  color: rgba(150, 150, 150, 1);
}

/* REMOVED Left for reference */
.dm_header_menuBack {
  padding: 10px 20px;
  width: 225px;
  cursor: pointer;
}

.dm_header_logo {
  padding: 20px 20px;
  width: 225px;
  background-image: url('../../img/logo.png');
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
}
.dm_header_layoutName{
  font-family: "lato";
  text-transform: uppercase;
  width: 440px;
  text-align: center;
  padding: 0px 40px;
  font-weight: 700;
}
.dm_header_modeContainer {
  width: 225px;
  height: 27px;
  flex-shrink: 0;
}




/*
 * Designer Mode Switch
 */


 .dm_header_switchContainer {
  display: flex;
  align-items: center;
}


.dm_header_switchText {
  font-family: "Lato";
  font-weight: 100;
  float: left;
  font-size: 14px;
  line-height: 28px;
  padding: 0px 10px;
}

.dm_header_switch {
  display: inline-block;
  height: 18px;
  position: relative;
  width: 45px;
}

.dm_header_switch input {
  display:none;
}

.dm_header_slider {
  background-color: #7b7b7b;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

.dm_header_slider:before {
  background-color: rgba(0, 0, 0,0.5);
  bottom: -1px;
  content: "";
  height: 20px;
  left: 0px;
  position: absolute;
  transition: .4s;
  width: 20px;
}

input:checked + .dm_header_slider {
  background-color: rgb(180,9,9);
}

input:checked + .dm_header_slider:before {
  transform: translateX(26px);
}

.dm_header_slider.dm_header_round {
  border-radius: 34px;
}

.dm_header_slider.dm_header_round:before {
  border-radius: 50%;
  box-shadow: 0px 0px 9px rgba(0, 0, 0,0.5);
  background-image: url("../../img/slider_button.png");
  background-size: cover;
}